import './MagicButton.css';
import { ReactComponent as MagicIcon } from '../../data/magic.svg';
import * as magic from './magic';

export default function MagicButton() {
  const clickButton = magic.clickButtonForComponents;

  return (
    <>
      <button id="button" className="ready" onClick={clickButton}>
        <MagicIcon className="magic_icon" />
      </button>
    </>
  );
}
