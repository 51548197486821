import './App.css';
import React, { useState } from 'react';
// import Item from './Item/Item';
import { ReactComponent as LogoIcon } from './data/converted.svg';
import { ReactComponent as VectorIcon } from './data/Vector.svg';
import { ReactComponent as MessageIcon } from './data/message.svg';
import { ReactComponent as ArrowIcon } from './data/arrow.svg';
import MagicButton from './components/MagicButton/MagicButton';
import Loading from './components/LoadingAnimation/LoadingAnimation';
import { allLanguages } from './languages/languages';
import shortid from 'shortid';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState('UK');

  setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  const keys = Object.keys(allLanguages);

  return (
    <>
      <canvas id="canvas"></canvas>

      <div className="container">
        {isLoading && <Loading />}

        <section className="section">
          <MagicButton />
          <LogoIcon className="logo_icon" />
          <div className="language_in_1_section">
            <input type="checkbox" id="hmt_1" className="hidden-menu-ticker" />

            <label className="btn-menu" htmlFor="hmt_1">
              {currentLanguage}
              <VectorIcon className="Message" />
            </label>

            <ul className="hidden-menu">
              {keys.map(key => {
                if (currentLanguage !== key) {
                  return (
                    <li className="menu-item" key={shortid.generate()}>
                      <button
                        type="button"
                        onClick={() => setCurrentLanguage(key)}
                        className="btn-lang"
                      >
                        {key}
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div className="section_item">
            <span className="container_title--number">01</span>
            <h2 className="container_title">CALL HOUSE</h2>
            <p className="container_text">
              {currentLanguage === 'UK' && allLanguages.UK.callhouse.p1}
              {currentLanguage === 'RU' && allLanguages.RU.callhouse.p1}
              {currentLanguage === 'EN' && allLanguages.EN.callhouse.p1}
            </p>
            <p className="container_text container_text--line">
              {currentLanguage === 'UK' && allLanguages.UK.callhouse.p2}
              {currentLanguage === 'RU' && allLanguages.RU.callhouse.p2}
              {currentLanguage === 'EN' && allLanguages.EN.callhouse.p2}
            </p>

            <div className="container_site">
              <a
                href="https://800.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon_box">
                  <ArrowIcon className="arrow_icon" />
                </span>
              </a>
              <a
                href="https://800.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
                className="container_title site"
              >
                <span className="site--line">
                  {currentLanguage === 'UK' && 'Сайт'}
                  {currentLanguage === 'RU' && 'Сайт'}
                  {currentLanguage === 'EN' && 'Site'}
                </span>
              </a>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="section_item">
            <span className="container_title--number">02</span>
            <h2 className="container_title">SMELO</h2>
            <p className="container_text">
              {currentLanguage === 'UK' && allLanguages.UK.smelo.p1}
              {currentLanguage === 'RU' && allLanguages.RU.smelo.p1}
              {currentLanguage === 'EN' && allLanguages.EN.smelo.p1}
            </p>
            <p className="container_text container_text--line">
              {currentLanguage === 'UK' && allLanguages.UK.smelo.p2}
              {currentLanguage === 'RU' && allLanguages.RU.smelo.p2}
              {currentLanguage === 'EN' && allLanguages.EN.smelo.p2}
            </p>
            <div className="container_site">
              <a
                href="https://smelo.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon_box">
                  <ArrowIcon className="arrow_icon" />
                </span>
              </a>
              <a
                href="https://smelo.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
                className="container_title site"
              >
                <span className="site--line">
                  {currentLanguage === 'UK' && 'Сайт'}
                  {currentLanguage === 'RU' && 'Сайт'}
                  {currentLanguage === 'EN' && 'Site'}
                </span>
              </a>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="section_item">
            <span className="container_title--number">03</span>
            <h2 className="container_title">CRM SERVISE</h2>
            <p className="container_text container_text--line">
              {currentLanguage === 'UK' && allLanguages.UK.crm.p1}
              {currentLanguage === 'RU' && allLanguages.RU.crm.p1}
              {currentLanguage === 'EN' && allLanguages.EN.crm.p1}
            </p>
            <div className="container_site">
              <a
                href="http://crm-service.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon_box">
                  <ArrowIcon className="arrow_icon" />
                </span>
              </a>
              <a
                href="http://crm-service.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
                className="container_title site"
              >
                <span className="site--line">
                  {currentLanguage === 'UK' && 'Сайт'}
                  {currentLanguage === 'RU' && 'Сайт'}
                  {currentLanguage === 'EN' && 'Site'}
                </span>
              </a>
            </div>
          </div>
        </section>

        <section className="section">
          <MagicButton />
          <a href="mailto:info@800.com.ua" className="mailto">
            <span className="feedback-message">
              {currentLanguage === 'UK' && 'Напишіть нам'}
              {currentLanguage === 'RU' && 'НАпишите нам'}
              {currentLanguage === 'EN' && 'Write to us'}
              <MessageIcon className="Message" />
            </span>
          </a>

          <div className="language_in_4_section">
            <input type="checkbox" id="hmt" className="hidden-menu-ticker" />

            <label className="btn-menu" htmlFor="hmt">
              {currentLanguage}
              <VectorIcon className="Message" />
            </label>

            <ul className="hidden-menu">
              {keys.map(key => {
                if (currentLanguage !== key) {
                  return (
                    <li className="menu-item" key={shortid.generate()}>
                      <button
                        type="button"
                        onClick={() => setCurrentLanguage(key)}
                        className="btn-lang"
                      >
                        {key}
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div className="section_item">
            <span className="container_title--number">04</span>
            <h2 className="container_title">PR STRATEGY</h2>
            <p className="container_text">
              {currentLanguage === 'UK' && allLanguages.UK.pr.p1}
              {currentLanguage === 'RU' && allLanguages.RU.pr.p1}
              {currentLanguage === 'EN' && allLanguages.EN.pr.p1}
            </p>
            <p className="container_text container_text--line">
              {currentLanguage === 'UK' && allLanguages.UK.pr.p2}
              {currentLanguage === 'RU' && allLanguages.RU.pr.p2}
              {currentLanguage === 'EN' && allLanguages.EN.pr.p2}
            </p>
            <div className="container_site">
              <a
                href="http://pr-strategy.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="icon_box">
                  <ArrowIcon className="arrow_icon" />
                </span>
              </a>
              <a
                href="http://pr-strategy.com.ua/"
                target="_blank"
                rel="noreferrer noopener"
                className="container_title site"
              >
                <span className="site--line">
                  {currentLanguage === 'UK' && 'Сайт'}
                  {currentLanguage === 'RU' && 'Сайт'}
                  {currentLanguage === 'EN' && 'Site'}
                </span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default App;
