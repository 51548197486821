import './LoadingAnimation.css';
export default function Loading() {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="content__container">
          <p className="content__container__text">the one-stop hub for</p>
          <ul className="content__container__list">
            <li className="content__container__list__item">call house</li>
            <li className="content__container__list__item">digital agency</li>
            <li className="content__container__list__item">crm manedge</li>
            <li className="content__container__list__item">pr company</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
